export default {
  // 全局
  app: {
    name: "HR端管理",
    updates: "系统更新，请刷新页面！",
  },
  // 首页
  home: {
    welcome: {
      hello: "你好",
      login: "欢迎登录",
    },
    shop: "EC商城优选",
    member: "普通会员",
    balance: "余额",
    use: "去使用",
    service: "专属客户经理",
    records: "记录HR成长",
    downloadUseBook: "是否下载使用手册？",
    useBook: "使用手册",
    card1: {
      title: "背景调查",
      btn: "新建委托",
      tips: "{name}用于背调服务的结算可在资源中心兑换获得",
      field2: "待查对账单",
      field3: "新增委托单量",
      field4: "未读报告",
      field5: "待修改委托单量",
    },
    card2: {
      title: "员工福利平台",
      btn: "管理平台",
      tips: "{name}可发放给员工兑换福利商品可在资源中心兑换获得",
      field2: "员工数",
      field3: "本周活跃",
      field4: "本周消费",
      field5: "总消费",
    },
    card3: {
      title: "员工激励平台-小妙计",
      btn: "审核妙计",
      tips: "{name}可发放给员工兑换福利商品可在资源中心兑换获得",
      field2: "员工数",
      field3: "本周活跃",
      field4: "通过审核",
      field5: "发放奖励",
    },
    card4: {
      title: "员工敬业度/满意度调研",
      btn: "发布问卷",
      tips: "{name}可购买问卷题目和调研员工数可在资源中心兑换获得",
      field2: "进行中",
      field3: "已结束",
      field4: "收集问卷数",
      field5: "参与人数",
    },
    card5: {
      title: "行业薪酬",
      btn: "定制报告",
      tips1: "提示：可同时选择项",
      tips2: "项",
      default: "系统默认指标",
      link: "以上数据均由薪智提供，更多行业数据、职位薪资可点击",
      more: "了解更多",
      lastOne: "至少选择一个指标！",
      auth: {
        title: "授权提示",
        note: "您即将跳转到“薪智”系统并自动登录，是否将您的账号信息同步？",
        required: "（必选）",
        company: "公司名称",
        email: "邮箱",
        name: "姓名",
        phone: "手机号码",
        confirm: "同意并使用",
      },
    },
  },
  // 全局消息提醒
  showMsg: {
    success: {
      made: "成功",
      change: "修改成功!",
      submit: "提交成功!",
      save: "保存成功!",
      delete: "删除成功!",
      add: "添加成功!",
      operation: "操作成功!",
      audit: "审核成功！",
      grant: "发放成功!",
      copy: "复制成功!",
    },
    confirm: {
      exitTitle: "退出确认",
      exit: "确定要退出登录吗？",
      batchTitle: "批量{msg}",
      batch: "确认要进行批量{msg}操作吗？",
    },
    warning: {
      unDev: "功能暂未开放!",
      empty: "数据不能为空",
    },
    unAuthorized: "未登录",
    unPermission: "当前页面无权查看",
    helper1:
      "您可以将本页面截图后发送给客户经理，帮助您排查此次上传失败的原因。",
  },
  // 日期时间
  datetime: {
    today: "今天",
    yesterday: "昨天",
    week: "本周",
    lastWeek: "上周",
    month: "本月",
    lastMonth: "上月",
    quarter: "本季度",
    lastQuarter: "上季度",
    year: "今年",
    lastYear: "去年",
    unit: {
      month: "月",
      monthFull: "月份",
      year: "年",
      quarter: "季",
      date: "日",
      hour: "时",
      hourFull: "小时",
      min: "分",
      minFull: "分钟",
      second: "秒",
      secondFull: "秒",
    },
  },
  // 货币
  currency: {
    price: "元",
    growthPoints: "成长积分",
  },
  // 文件上传下载
  file: {
    file: "文件",
    format: "文件格式错误!",
    formatLimit: "只能上传{format}格式文件，文件不能超过{size}",
    sizeMax: "文件不能超过{value}",
    upload: "上传",
    downloadSelect: "请选择要下载的文件",
  },
  // 按钮
  button: {
    filter: "查询",
    reset: "重置",
    resetPwd: "重置密码",
    import: "导入",
    batchImport: "批量导入",
    export: "导出",
    batchExport: "批量导出",
    edit: "编辑",
    remove: "删除",
    open: "开启",
    close: "关闭",
    statusT: "启用",
    statusR: "停用",
    confirm: "确定",
    download: "批量下载",
    downloadS: "下载",
    back: "返回",
    new: "添加",
    cancel: "取消",
    upload: "重新上传",
    previous: "上一步",
    next: "下一步",
    newS: "批量添加",
    dc: "到场",
    batchDel: "批量删除",
    candidate: "候选人",
    check: "快速核验",
    detail: "查看详情",
    detailWeb: "查看网页录入详情",
    order: "进行委托",
    add: "新建",
    newAdd: "新增",
    sexport: "导出",
    batchAdd: "批量新建",
    uploadDragger: "将文件拖到此处，或点击上传",
    confirmDelete: "确认删除吗？删除后数据不可恢复！",
    confirmReset: "确认重置吗？重置后数据不可恢复！",
    confirmCancel: "确认取消吗？取消后数据不可恢复！",
    confirmAgree: "确认同意{msg}吗？同意{msg}后操作不可撤销！",
    confirmRefuse: "确认拒绝{msg}吗？拒绝{msg}后操作不可撤销！",
    audit: "审核",
    batchDeleteSelected: "请选择至少一条数据",
    yes: "是",
    no: "否",
    tips: "提示",
    set: "设置",
    submit: "提交",
    save: "保存",
    saveAndUp: "保存并上架",
    permission: "权限",
    disable: "禁用",
    enable: "激活",
    offShelf: "下架",
    onShelf: "上架",
    offShelfConfirm: "确认下架吗？",
    onShelfConfirm: "确认上架吗？",
    agree: "同意",
    refuse: "拒绝",
    pass: "通过",
    reject: "未通过",
    view: "查看",
    done: "完成",
    saveAdd: "保存后继续创建",
    distribution: "分配",
    none: "暂无",
    addQuick: "新建快速",
    addBatchQuick: "批量导入快速",
    delay: "延时",
    previewBig: "点击查看大图",
    continue: "继续",
    copy: "复制",
    confirmation: "确认",
    modify: "修改",
    reSend: "重新发送",
    reload: "刷新",
  },
  // 表格公用字段
  table: {
    createBy: "创建人",
    createTime: "创建时间",
    updateBy: "更新人",
    updateTime: "更新时间",
  },
  // 表单公用字段
  form: {
    auto: "自动生成",
  },
  // 账户账号
  user: {
    username: {
      label: "手机号或ID",
      placeholder: "请输入手机号或特定ID",
      validation: "手机号或特定ID不能为空",
      mobile: "请输入正确的手机号或特定ID",
    },
    password: {
      label: "密码",
      placeholder: "请输入登录密码",
      placeholderNew: "新密码",
      validation: "密码不能为空",
      strong: "密码由数字和字母组成",
      minLength: "密码不能小于6位",
      maxLength: "密码不能超过{length}位",
    },
    mobileCode: {
      label: "验证码",
      placeholder: "短信验证码",
      validation: "验证码不能为空",
      suffix: "获取验证码",
      counterText: "s后可重发",
      send: "验证码已发送",
      chineseMainlandOnly: "验证码登录仅支持中国大陆手机号",
    },
    resetPwd: {
      title: "找回密码",
      tips: "须由6位以上数字和字母组成, 区分大小写",
      requiredLength: "长度{length}位以上",
      requiredType: "包含{type}",
      requiredTypeNumber: "数字",
      requiredTypeLetter: "字母",
      requiredTypeSymbol: "特殊符号",
      caseSensitive: "区分大小写",
    },
    company: {
      label: "公司名称",
      placeholder: "请输入公司名称",
      validation: "公司名称不能为空",
    },
    name: {
      label: "您的姓名",
      placeholder: "请输入您的姓名",
      validation: "您的姓名不能为空",
    },
    remark: {
      label: "您的需求",
      placeholder: "请输入您的需求",
      validation: "您的需求不能为空",
    },
    code: {
      label: "验证码",
      placeholder: "请输入验证码",
      validation: "验证码不能为空",
    },
    title: "欢迎登录到",
    subTitle: "HR 端管理",
    signInTip: "已有账号? ",
    mobileSignIn: "请登录pc端进行注册",
    signInSubTip: "立即登录",
    signUpTip: "没有账号吗 ?",
    signUpTip2: "没有账号? ",
    signUpSubTip: "注册新账号",
    signUpSubTip2: "立即注册",
    signUpTitle: "企业用户注册",
    signUpTitleSub: "请正确填写以下信息,信息审核通过后注册成功。",
    remember: "记住我",
    forget: "忘记密码 ?",
    validation: "请正确填写登录信息！",
    submit: "登录",
    signUp: "注册",
    signUpDone: "我们将会尽快联系您，请您注意接听我们的客服电话！",
    noCompany: "当前账号无可登录主体，请联系系统管理员!",
    selectCompany: "请选择要登录的主体",
    slideTip: "请按住滑块，拖动到最右边",
    slideSuccess: "验证通过",
    slideWarning: "请先进行滑块验证！",
    verification: "校验身份",
    bindEmail: "绑定邮箱",
    bindEmailRequired: "请选择要绑定的邮箱",
    bindEmailTips: "请选择您需要绑定的邮箱，由于手机号码不可重复，",
    bindEmailTips2: "其他未被选择的邮箱需要重新绑定新的手机号码后可使用",
    bindEmailConfirm1: "您确定要将",
    bindEmailConfirm2: "与手机号码",
    bindEmailConfirm3: "绑定吗？",
    reloadCodeImg: "点击刷新",
    signOut: "退出登录",
    backVersion: "返回旧版",
    tabs: {
      pwd: "密码登录",
      sms: "验证码登录",
    },
    unVerified: "请验证您的手机号码后进行其他操作",
    mobileVerify: "手机号码验证",
    emailLoginWarning: "请使用手机号或特定ID登录",
    passwordExpired: "密码已过期",
    temporaryLock: "登录失败次数较多，请{min}分钟后再试",
  },
  // 菜单
  menu: {
    empty: "当前用户未配置菜单，请联系管理员！",
    index: "首页",
    // 首页
    workspace: {
      index: "工作台",
      backCheck: "背景调查",
      toonPlat: "福利平台",
    },
    hr: "人事管理",
    // 资源中心
    resource: {
      index: "资源中心",
    },
    // 会员中心
    member: {
      index: "会员中心",
    },
    // 配送管理
    express: {
      index: "配送管理",
      express: "快递公司",
      cost: "快递资费",
    },
    // 系统管理
    sys: {
      index: "系统管理",
      dic: "数据字典",
      menu: "菜单管理",
      role: "角色管理",
      user: "用户管理",
      org: "组织架构",
      config: "系统配置",
      company: "公司管理",
      exchange: "企业兑换配置",
      benefits: "系统福利",
    },
    // 商品管理
    goods: {
      goods: "商品管理",
      category: "商品分类",
      spec: "商品规格",
      label: "促销标签",
      list: "商品列表",
      banner: "Banner管理",
    },
    // 供应商管理
    suppliers: {
      suppliers: "供应商管理",
      suppliersList: "供应商列表",
      brand: "品牌列表",
      coupon: "优惠券管理",
    },
    // 委托管理
    order: {
      index: "委托管理",
      list: "委托列表",
      detail: "新建委托",
      report: "查看报告",
    },
    // 订单管理
    orderManagement: {
      top: "订单管理",
      list: "订单查询",
      afterlist: "售后管理",
    },
    // 易查宝管理
    echeck: {
      index: "易查宝管理",
      list: "易查宝列表",
      detail: "新建快速背调",
    },
    // 对账单列表
    bill: {
      index: "对账单列表",
      list: "对账单列表",
    },
    // 候选管理
    canddt: {
      index: "候选管理",
      list: "候选人管理",
    },
    // 招聘公司来源
    source: "招聘公司来源",
    // 生态绑定
    bind: "生态绑定",
    system: "系统banner列表页",
    // 个人中心
    person: {
      index: "个人中心",
      account: "账户管理",
      avatar: "修改头像",
      authority: "权限设置",
    },
    // 消息
    message: {
      index: "消息",
      list: "消息列表",
      report: "报告进度信息",
    },
    // 到场记录
    present: {
      index: "到场记录",
      code: "到场二维码",
      fb: "快速批量核验",
      fs: "快速单个核验",
      tb: "传统批量核验",
      ts: "传统单个核验",
      add: "新建到场信息",
      info: "查看候选人基本信息",
    },
    // 帮助
    help: {
      index: "帮助",
      download: "下载管理",
      tips: "帮助提示",
    },
    // 系统更新记录
    update: "系统更新记录",
  },
  // 工作台-背景调查
  backCheck: {
    // 首页
    daochang: "新建到场",
    order: "新建委托",
    tips: "温馨提示",
    progress: "进度",
    qsbd: "快速背调",
    hxrgl: "候选人管理",
    // 到场记录
    addSource: "新建招聘来源",
    source: "候选人招聘来源",
    cname: "候选人姓名",
    cidcard: "候选人证件号码",
    cphone: "候选人电话号码",
    hr: "登记HR",
    time: "登记时间",
    status: "授权状态",
    dctitle: "到场记录",
    done: "已授权",
    undo: "未授权",
    authType: "授权方式",
    authOnline: "线上收集",
    authOnlineTip1:
      "没有候选人的授权材料，需要系统在创建背调委托后，向候选人发送授权申请",
    authOnlineTip2: "授权成功后将进行核实工作",
    authOffline: "已获得授权",
    authOfflineTip1:
      "已有候选人的授权材料（包括易查背调提供二维码获取授权的方式），无需易查背调再次收集",
    authOfflineTip2: "请保管好授权证明文件，数据源将定期抽查汇总",
    overseasCandidateDataTip:
      "无身份证信息的境外候选人，如需下单，可以通过发送邮件至 {email} 或联系客服进行操作",
    overseasCandidateDataTipHrefTitle: "点击查看详细描述",
    overseasCandidateDataEmailSubject: "境外候选人委托",
    stepStatus: "阶段状态",
    signType: "报告级别",
    oneitem: "至少选择{num}条数据",
    moreitem: "最多选择{num}条数据",
    authModal: {
      title: "您选中的记录中有的候选人还有没授权",
      tip: "系统会自动向候选人收取授权，候选人授权成功后，系统中的“状态”字段会自动变更为“已授权”。",
    },
    backType: "背调类型",
    noAuth: "候选人未授权，目前无法下载授权书",
    noPic: "暂无照片",
    siteInfo: "现场信息",
    noSign: "暂无签名",
    // 新建到场
    dcTitle: "录入到场信息",
    dcAddTitle: "编辑候选人信息",
    dcHasAddTitle: "已添加候选人",
    dcNotAddTitle: "未添加候选人",
    registrationSource: "记录来源",
    // 新建委托
    orPackageSelect: "模板选择",
    alertTitle: "温馨小提示",
    alertDesc1: "深度核实模块内容（后面的数字代表几段的履历）",
    alertDesc2: "若想配置更多模块，请联系我们哦!",
    // 候选人管理
    hname: "候选人姓名",
    hphone: "候选人电话号码",
    hphoneShort: "候选人电话号码",
    hstatus: "状态",
    hprocess: "进展情况",
    hflowup: "跟进情况",
    hconcat: "候选人电话号码",
    hicard: "候选人身份证号",
    hpstatus: "阶段状态",
    sms: "短信验证",
    smscode: "发送验证码",
    hrCheck: "请HR校验身份",
    resetsms: "重发验证码",
    smsuccess: "短信验证码发送成功",
    smsExpired: "{min}前有效！",
    smsInvalid: "验证码错误或已经失效，请重发验证码或稍后再试！",
    sendText: "{second}s后可重发",
    exporting: "导出处理中",
    downFile: "下载导出文件",
    templateWorkOrder: "员工信息导入模版.xlsx",
    report: {
      client: "委托方",
      submitDate: "报告提交日期",
      idCard: "证件号码",
      show: "显示",
      noShow: "不显示",
      problemName: "候选人提供信息",
      summaryValue: "详细说明",
      authenticity: "真实性",
      empty: "未查询到相关信息",
      certification: {
        title: "认证内容",
        organization: "认证机构",
        number: "证书编号",
        time: "认证时间",
      },
      commercialConflict: {
        nameLegalPerson: "企业法人信息",
        nameShareholder: "企业股东信息",
        nameLegalManager: "企业主要管理人员信息",
        columnNamePerson: "查询人姓名",
        columnNameEnterprise: "企业(机构)名称",
        columnRegNo: "注册号",
        columnRegCap: "注册资本(万元)",
        columnRegCapCur: "注册资本币种",
        columnStatusEnterpeise: "企业状态",
        columnSubCapCon: "认缴出资额(万元)",
        columnTitle: "职务",
      },
      creditRisk: {
        caseNumber: "案件编号",
        province: "省份",
        court: "法院名称",
        status: "履行情况",
        reason: "原因",
        releaseDate: "发布日期",
      },
      limitConsumption: {
        caseNumber: "案件编号",
        court: "法院名称",
        filingDate: "立案日期",
        releaseDate: "发布日期",
      },
      litigationInformationInquiry: {
        riskPostive: "存在风险",
        riskNegative: "无风险",
        productNameCivilCase: "民事案件检索",
        productNameCriminalCase: "刑事案件检索",
        productNameAdministrationCase: "行政案件检索",
        productNameExecutionCase: "执行案件检索",
        productNameLiquidationBankruptcy: "强制清算与破产信息检索",
        productNameNonLitigationSecurity: "非诉保全审查",
        tableNameSummaryCases: "涉诉案件概览",
        tableNameSummaryParties: "涉诉角色概览",
        tableNameCivilCase: "民事案件",
        tableNameCriminalCase: "刑事案件",
        tableNameAdministrationCase: "行政案件",
        tableNameExecutionCase: "执行案件",
        tableNameLiquidationBankruptcy: "强制清算与破产",
        tableNameNonLitigationSecurity: "非诉保全审查",
        tableNameYear: "立案年份",
        tableNameSummary: "案由概类",
        tableNameParty: "涉案角色",
        tableNameLocation: "涉案地区",
        tableNameAmount: "涉案金额",
        tableNameResult: "结案方式",
        partyPlaintiff: "原告",
        partyDefendant: "被告",
        partyThird: "第三人",
      },
      financialViolation: {
        tableColumnIndex: "第{index}条",
        tableColumnDocumentNumber: "行政处罚决定书文号",
        tableColumnOrganizationPunishment: "行政处罚决定机关名称",
        tableColumnEnterprise: "所在公司名称",
        tableColumnLegalRepresentative: "法定代表人",
        tableColumnName: "当事人名称",
        tableColumnParty: "当事人类型",
        tableColumnGender: "当事人性别",
        tableColumnBirthday: "当事人生日",
        tableColumnAddress: "当事人住址",
        tableColumnTitle: "当事人时任",
        tableColumnRegistrationNumber: "当事人注册号",
        tableColumnFormerName: "当事人历史名称",
        tableColumnPunishmentType: "处罚类型",
        tableColumnPunishmentBasis: "处罚依据",
        tableColumnPunishmentContent: "行政处罚内容",
        tableColumnIllegalFact: "违法事实",
        tableColumnDocumentDate: "文书日期",
        tableColumnOrganizationPublish: "公示机关",
        tableColumnLocation: "区域名称",
        tableColumnMatchedDegree: "匹配度",
      },
      overdue: {
        tableColumnTotal: "过去两年累计逾期次数",
        tableColumnLatestYear: "最近一次逾期发生年份",
        tableColumnClass1: "1类",
        tableColumnClass2: "2类",
        tableColumnClass3: "3类",
        tableColumnClass4: "4类",
      },
    },
    // 委托记录
    hprt: "阶段性报告时间",
    hcs: "任务状态",
    hc: "全部候选人",
    hecheckr: "易查宝记录",
    echeckName: "易查宝",
    horderr: "委托记录",
    hqt: "登记时间",
    hbatchdc: "批量导入到场",
    hbatchbd: "批量背调",
    hbatchorder: "批量委托",
    hauthselectdata: "您选中的数据",
    hnoauth: "包含未授权候选人",
    hauthtip:
      '系统会自动向候选人收取授权，候选人授权成功后，系统中的“状态"字段会自动变更为“已授权”。',
    hauthtype: "添加类型",
    hcname: "抄送人姓名",
    completeReportTime: "终版报告时间",
    template: "模板",
    module: "模块",
    indexECharts: {
      collectOver: "信息收集完成",
      collecting: "信息收集中",
      conductSum: "进行中",
      overSum: "已完成",
      revokeSum: "撤销核实",
      stopSum: "停止核实",
      total: "总委托单量",
    },
    interview: {
      detail: {
        title: "网页录入到场信息",
        subTitle: "候选人基本信息",
      },
    },
    merged: {
      tabTitle: "背调记录",
      packageName: "报告版本",
      consignTime: "委托时间",
      guidance: "易查宝记录和委托记录合并",
      guidanceOk: "知道了",
    },
    // backCheck.qrCodeAuthList 二维码授权列表
    qrCodeAuthList: {
      title: "二维码授权列表",
      downloadBook: "下载授权书",
      authTime: "授权时间",
      selectedTotal: "已选择{count}个委托书",
      unSelectedMsg: "请勾选授权书，再点击此处批量创建",
    },
  },
  // 工作台-福利平台
  welfarePlat: {
    index: {
      memberCount: "员工数",
      weekActive: "周活跃度",
      unlogin: "未登录",
      logined: "登录",
      active: "激活",
      unActive: "未激活",
      accountRecord: "动账记录",
      baseSet: {
        title: "平台基本设置",
        name: "福利平台名称",
        nameRequired: "名称不能为空",
        nameMax: "名称不能超过{length}个字符",
        logo: "平台logo",
        logoTips: "建议尺寸：宽 468px , 高204px",
        logoRequired: "logo不能为空",
        moneyName: "平台货币名称",
        moneyNameTips: "最多{length}个汉字",
        moneyNameRequired: "货币名称不能为空",
        moneyNameMax: "货币名称不能超过{length}个字符",
        tool: "是否使用激励工具",
        toolTips: "启用后员工即可在平台中使用激励工具",
      },
      allocationPoints: {
        title: "兑换{name}",
        canAllocation: "可分配",
        consumptionWeek: "本周消费",
        consumptionRecords: "消费明细",
        consumptionTotal: "员工总消费",
        consumptionTitle: "员工消费明细",
        consumptionCol1: "商品",
        consumptionCol2: "员工姓名",
        consumptionCol3: "消费{name}",
        consumptionCol4: "订单状态",
        consumptionCol5: "时间",
        consumptionCol6: "服务费",
        allocationHandle: "手动发放",
        allocationAuto: "自动发放",
      },
      accountRecords: {
        title: "动账记录",
        col1: "时间",
        col2: "类型",
        col3: "对象",
        col4: "金额",
        col5: "账户余额",
        col6: "服务费",
      },
    },
    // HR 手动发放福币
    manualRelease: {
      title: "手动发放",
      single: "单人发放",
      batch: "批量发放",
      form: {
        canUse: "可用",
        userIds: "员工姓名",
        userIdsPlaceholder: "请选择员工姓名",
        userIdsRequired: "员工姓名不能为空",
        mobile: "手机号码",
        mobilePlaceholder: "请输入手机号码",
        mobileRequired: "手机号码不能为空",
        mobileReg: "手机号码格式错误",
        email: "员工邮箱",
        emailPlaceholder: "请输入员工邮箱",
        emailRequired: "员工邮箱不能为空",
        emailReg: "员工邮箱格式错误",
        points: "发送数量",
        pointsPlaceholder: "请输入此次发放的福利数量",
        pointsRequired: "福利数量不能为空",
        pointsRequired2: "发放金额不能为空",
        pointsReg: "发放金额必须为有效数字",
        benefitsId: "企业福利",
        benefitsIdPlaceholder: "请选择企业福利",
        benefitsIdRequired: "企业福利不能为空",
        payPassword: "支付密码",
        payPasswordPlaceholder: "请输入支付密码",
        payPasswordRequired: "支付密码不能为空",
        currency: "币种",
        remark: "备注",
        remarkPlaceholder: "请输入备注",
        filePlaceholder: "请上传导入文件",
        fileTips: "请选择企业福利以及输入支付密码后上传文件",
      },
      batchTips: "请先下载模板，填写后上传即可",
      batchDownload: "下载",
      batchUpload: "上传",
      editType: "编辑企业福利",
      batchFileName: "批量发放文件",
      readExcelFailed: "文件解析失败，技术信息：{msg}。",
      readExcelMessage: "第{index}行：{msg}。",
      readExcelConfirm: "确认发放金额无误并为以下员工发放？",
      readExcelConfirmOk: "确认发放",
    },
    // 企业福利
    companyBenefits: {
      title: "企业福利",
      formTitle: "编辑{name}",
      namePlaceholder: "请选择企业福利",
      name: {
        label: "福利名称",
        placeholder: "请输入福利名称",
        required: "福利名称不能为空",
        max: "福利名称不能超过{length}个字符",
      },
      benefitsType: {
        label: "福利类型",
        placeholder: "请选择福利类型",
        required: "福利类型不能为空",
      },
      companyId: {
        label: "企业名称",
        placeholder: "请选择企业",
        required: "企业名称不能为空",
      },
      grantObject: {
        label: "发放对象",
        placeholder: "请选择发放对象",
        required: "发放对象不能为空",
      },
      userIds: {
        label: "发放员工",
        placeholder: "请选择发放员工",
        required: "发放员工不能为空",
      },
      grantTime: {
        label: "发放时间",
        placeholder: "请选择发放时间",
        required: "发放时间不能为空",
        birthday: "按员工阳历生日当天发放",
      },
      num: {
        label: "数量",
        placeholder: "请输入数量",
        required: "数量不能为空",
      },
      state: {
        label: "状态",
      },
      isAuto: {
        label: "自动发送",
      },
      isSysBenefits: {
        label: "系统福利",
      },
      remark: {
        label: "备注",
        placeholder: "请输入福利备注",
        max: "备注不能超过{length}个字符",
      },
      employment: {
        label: "司龄",
        placeholder: "请添加司龄",
        required: "司龄不能为空",
        year: "发放司龄({unit})",
        yearRequired: "发放司龄不能为空",
        yearUniq: "第{sn1}行与第{sn2}行司龄重复！",
        points: "发放数量({name})",
        pointsRequired: "发放数量不能为空",
        btn: "编辑司龄",
      },
    },
    // 自动发放
    autoManualRelease: {
      title: "自动发放",
      add: "新增发放方案",
      list: {
        col1: "福利类型",
        col2: "福利名称",
        col3: "发放金额({name})",
        col4: "已领取人数",
        col5: "创建时间",
        col6: "是否启用",
      },
    },
  },
  // 首页
  index: {
    grxx: {
      title: "个人信息",
      hello: "上午好，",
      welcome: "易查背调欢迎您",
      service: "我的专属客服经理",
      customer: "客户服务部",
      email: "邮箱",
      contact: "联系方式",
    },
    ksxj: {
      title: " 快速新建",
      daochang: "新建到场",
      order: "新建委托",
      qsbd: "快速背调",
    },
    sjjb: {
      title: "数据简报",
      dzd: "待查对账单",
      wdbg: "未读报告",
      wtd: "待修改委托单",
      wtdl: "新增委托单量",
    },
    xjjl: "消息记录",
    noMsg: "暂无消息记录",
    wtdl: "委托单量(份)",
    dpdj: "盾牌等级分布",
    wtdlqs: "{year}年委托单量趋势",
    jdbgsl: "近30天阶段报告数量（份）",
    fxfx: "候选人招聘风险分析",
    gzsl: "近30天委托单进度跟踪数量（份）",
    more: "查看全部",
    unit: "份",
  },
  // 委托
  order: {
    // 列表
    title: {
      query: "快速查询",
      list: "委托列表",
      name: "委托",
    },
    // 有用
    filter: {
      name: "候选人姓名",
      number: "报告编号",
      hr: "委托HR",
      status: "报告状态",
      level: "报告级别",
      time: "登记时间",
      confirm: "信息确认时间",
      report: "接收报告时间",
      process: "任务进度",
    },
    table: {
      update: "变更状态",
      data: "资料管理",
      detail: "报告详情",
      stop: "停止核实",
    },
    dataForm: {
      title: "候选人资料管理",
      filename: "文件名称",
      type: "类型",
      updateTime: "最后修改时间",
    },
    stopForm: {
      desc: "变更说明",
      descRequired: "请输入变更说明",
    },
    button: {
      add: "新建委托",
    },
    // 报告
    report: {
      info: "基础信息",
      view: "概述",
      table: {
        name: "模块名称",
        status: "状态",
        show: "显示状态",
        desc: "概述说明",
        dp: "盾牌",
      },
    },
    // 详情
    detail: {
      info: {
        custom: "客户名称",
        area: "所属地区",
        mobile: "委托HR手机",
        address: "报告发送邮箱",
        telephone: "委托HR座机",
        cc: "抄送邮箱",
        addcc: "添加抄送邮箱",
        confirm: "确定要删除邮箱地址？",
      },
      name: "候选人姓名",
      sex: "候选人性别",
      phone: "候选人电话号码",
      phoneCode: "候选人电话号码区号",
      urgent: "是否加急",
      lang: "报告语言",
      langEmail: "邮件语言",
      process: "报告进度跟进",
      entry: "预计入职时间",
      email: "候选人邮箱",
      agency: "拟入职机构",
      address: "工作地点",
      note: "备注",
      concat: "联系候选人",
      profile: "附件资源",
      upload: "上传附件",
      uploadFailed: "上传失败",
      uploadFailedMsg: "文件【{name}】上传失败：",
      uploadFailed400: "请检查您的网络链接是否正常！",
      uploadFailed300: "上传请求被重定向！请联系网管部门是否允许上传！",
      uploadFailedOther:
        "上传请求失败，技术信息：{msg}，请联系客服工作人员或稍后再试！",
      uploadFailedHelp: "若您需要帮助，请截图此处提示及错误码后联系客户经理。",
      uploadFailedTid: "错误码: ",
      uploadFailedAcceptError: "文件格式错误",
      uploadFailedAccept: "目前支持以下格式：",
      uploadFailedLimitError: "文件过大",
      uploadFailedLimit: "文件大小不能超过{limit}",
      uploadHelper: {
        modalTitle: "上传候选人文件失败",
        confirm: "文件上传失败，请打开文件上传助手",
        confirmCancel: "取消",
        confirmOk: "文件上传助手",
        confirmTips:
          "若您多次上传失败，可打开文件上传助手，按照提示使用其他方式发送文件。点击“取消”后关闭弹窗，继续下单。",
        confirmCloseTips:
          "此弹窗关闭后，无法再次打开，请确认您已解决上传文件失败的问题。",
        title: "本页导读",
        email: "使用邮箱发送文件",
        emailContent1: "此内容格式为系统自动识别，不可编辑",
        emailContent2: "您复制后，直接粘贴到邮件正文",
        emailContent3:
          "请选择您需要上传的所有关于该候选人的附件通过发送邮件的方式提交",
        emailContent4: "文件大小：小于50M",
        emailContent5: "支持的格式如下：",
        fallback: "问题反馈",
        way: "方法{no}：",
        tips: "请您按照下方提示步骤上传文件。",
        pe11: "适用电脑中已安装邮件客户端的用户。",
        pe12: "①点击下方按钮，唤起邮箱客户端。",
        pe13: "②选择您需要上传的附件到邮箱中，并确认邮件发送成功。",
        pe14: "一键发送",
        pe21: "适合电脑中没有安装邮件客户端的用户。",
        pe22: "①登录您的邮箱并打开写邮件页面。",
        pe23: "②请您点击核对信息。",
        pe24: "核对信息",
        pe25: "核对信息后，将展示③中的邮件内容",
        pe26: "③点击复制，将下方的收件人、主题、正文均复制粘贴到邮件中",
        pe27: "收件人：",
        pe28: "主题：",
        pe29: "正文：",
        pe210: "④选择您需要上传的附件到邮箱中，并确认邮件发送成功。",
        pe211:
          "⑤继续填写背调信息，当您提交背调信息后，系统将把您邮件中添加的文件附件与您背调委托的候选人自动关联。",
        fallbackTips:
          "您可以将本页面下方截图后发送给客户经理，帮助您排查此次上传失败的原因。",
        demoTips: "点击查看示例",
        demoImgAlt: "邮件样式示例",
        validBeforeEdit: "请先点击确认修改按钮！",
        callEmailClient: "邮箱唤起成功！",
      },
      source: "候选人招聘来源",
      addSource: "新建招聘来源",
      addCan: "添加候选人",
      costCenter: "成本中心",
      reportLang: "报告语言",
      isCanConcat: "是否可联系",
      isDelete: "确定要移除候选人吗?",
      zh: "中文",
      en: "英文",
      empty: "请添加候选人",
      emptyTemplate: "请选择模板",
      emptySource: "请选择招聘来源",
      templateTips1: "深度核实模块内容（后面的数字代表几段履历）",
      templateTips2: "若想配置更多模块，请联系我们哦！",
      templateTips3:
        "修改“履历”或“表现”的段数时，请确保“履历”的数量大于等于“表现”的数量",
      invalid: "报告单不存在！",
      expandMore: "展开更多",
      shrinkMore: "收起",
      joinGift: "入职礼",
      joinGiftTips:
        "在终版报告通过24小时后，将会通过邮件的方式向候选人获取收货地址",
      downloadBatchTemplate: "下载候选人模板表格",
      downloadBatchTemplateTips:
        "（导入前请下载最新模板，模板中的表头名称不可更改，表头行不能删除）",
      downloadBatchTemplateTitle: "上传表格",
      downloadBatchTemplateTips2:
        "文件后缀名必须为 xls 或 xlsx （即Excel格式），文件大小不得大于10M，最多支持导入3000条数据",
      downloadBatchTemplateTips3: "您选择的查询项，必填的内容包括：",
      downloadBatchTemplateTips4:
        "注意：为了您能成功上传，需要确保必填内容已填写完整",
      batchClear: "清除所有数据",
      batchClearConfirm: "确定要清除所有数据吗？数据清除后不可恢复！",
      batchDesc: "说明",
      batchDescP1: "您可在将候选人信息在模板中编辑后，一键上传文件",
      batchDescP2: "下方展示的数据仅作为数据预览，不可更改",
      batchDescP3:
        "导入数据请一次性全部上传，不支持追加多次导入，再次导入后数据将覆盖下方已上传的候选人信息",
      success: "操作成功",
      successAutoJump: "{s}s后自动跳转到候选人管理页面",
      selectedTemplate: "已选择模板",
      downloadQRCode: "下载二维码",
    },
    // 入职礼
    gift: {
      name: "入职礼",
      title: "员工入职礼",
      grantRecord: "入职礼发放记录",
      changeRecord: "入职礼变更记录",
      unit: "份",
      description: {
        t1: "1、系统将通过益点关爱福利平台派发入职礼。",
        t1s1: "(1)对于未注册的员工，将使用候选人订单信息注册福利平台账号；",
        t1s2: "(2)对于已注册但未关联到客户公司的账号，将进行关联操作。",
        t2: "2、在入职礼订单状态为未开始时，可随时取消；其他状态无法取消。",
        t3: "3、系统将向您选择的对象发送一封邮件，以获取员工的收货信息并告知员工入职礼进度等信息。",
        t4: "4、若员工未能在7天内通过邮件中提交信息，入职礼将被自动取消。",
        preT: "预定入职礼（以下规则仅在委托下单时勾选入职礼有效）：",
        pre1: "1、在新建委托单时选择的入职礼，仅在报告结果为绿盾、蓝盾时有效；其他报告级别将自动取消；",
        pre2: "2、若订单中途取消，未能出具终版报告，将取消预定的入职礼；",
        pre3: "3、系统将在终版报告出具后24小时，进入入职礼发放流程。",
      },
      grant: "发放入职礼",
      selected: "已选",
      number: "可用数量",
      list: {
        expNum: "快递编号",
        createTime: "操作下单时间",
        name: "入职礼套餐",
        reason: "取消原因",
        cancel: "取消入职礼",
        balanceLabel: "剩余总套餐数",
      },
      record: {
        value: "动账",
        last: "剩余数量",
      },
    },
  },
  // 易查宝列表
  echeck: {
    title: "候选人列表",
    filter: {
      time: "查询时间",
    },
    add: "批量传统委托",
    table: {
      idCard: "候选人证件号码",
      level: "职位级别",
      checkTime: "核验时间",
      mobile: "候选人电话",
      add: "添加核验模块",
      authorizationRequired: "是否需要授权",
    },
    // 详情
    detail: {
      package: "易查宝套餐选择",
      fsTitle: "候选人快速核验",
      create: "创建快速背调",
      level: "候选人级别",
      levelMax: "候选人级别不能超过{length}个字符",
      must: "必选",
      last: "剩余",
      times: "次",
      template: "员工信息导入模版-易查宝订单.xlsx",
      emptyModule: "易查宝套餐不能为空",
      emptyCandidate: "候选人不能为空",
      unsavedCandidate: "有未保存的候选人",
      authorizationRequired: "需要授权",
      candidateInfoImportFailedTitle: "上传的候选人部分信息不正确",
      candidateInfoImportFailedType: "第{line}行【{name}】：{msg}",
      candidateInfoImportResultTitle: "批量导入候选人",
      candidateInfoImportSuccessMessage:
        "已导入{count}条信息，请在页面底部核对",
      candidateInfoImportEmptyMessage: "没有获取到有效信息",
    },
  },
  // 对账单列表
  bill: {
    filter: {
      org: "所属机构",
      code: "付款码",
      time: "接收报告时间",
      status: "核算状态",
    },
  },
  // 招聘来源
  source: {
    tip: "显示已关闭渠道",
    add: "新建招聘来源",
  },
  // 个人中心
  person: {
    // 账户管理
    account: {
      title: "账户安全",
      old: "原密码",
      new: "新密码",
      confirm: "确认密码",
      edit: "确认修改",
      bindTitle: "账户绑定",
      mobile: "手机号码",
      check: "已验证",
      changeMobile: "修改手机号码",
      editMobile: "修改的手机号",
      code: "验证码",
    },
    // 编辑头像
    avatar: {
      title: "上传头像",
      preview: "头像预览",
      submit: "提交头像",
      select: "请选择你的头像",
    },
    // 权限设置
    permission: {
      filter: {
        title: "当前角色",
        depart: "部门名称",
        status: "状态",
      },
      account: {
        title: "全部账户",
        add: "添加子账户",
        had: "已选中",
        select: "条记录",
        table: {
          name: "昵称(系统名称)",
          mobile: "电话",
          depart: "主部门",
          second: "附属部门",
          status: "状态",
        },
      },
      set: {},
    },
  },
  // 消息记录
  message: {
    content: "消息内容",
    time: "时间",
    // 报告进度
    report: {
      process: {
        title: "任务进度",
        s1: "客户委托",
        s2: "资料收集",
        s3: "资料审核",
        s4: "报告制作",
        s5: "报告审核",
        s6: "阶段报告",
        s7: "终版报告",
        s8: "核算完成",
        s9: "候选人身份核实未通过",
        tip1: "蓝色图标：将涉及此流程",
        tip2: "灰色图标：不涉及此流程",
      },
      info: {
        order: "委托单信息",
        number: "委托单号",
        person: "委托单创建者",
        version: "报告版本",
      },
      canInfo: "候选人信息",
      order: "核实套餐",
    },
  },
  // 到场记录
  present: {
    code: "到场二维码",
    filter: {
      type: "到场记录类别",
      sblr: "设备录入",
      weblr: "网页录入",
    },
    list: {
      check: "快速核验",
      detail: "查看详情",
      order: "进行委托",
      hr: "登记HR",
      time: "登记时间",
      add: "添加到场",
      checkT: "核验方式",
    },
    // 核验
    verify: {
      fsList: "候选人快速核验列表",
      cphone: "候选人电话号码",
    },
    add: {
      title: "添加候选人信息",
    },
    info: {
      can: "候选人基本信息",
      card: "证件基本信息",
      tra: "传统背调",
      hometown: "籍贯",
      birth: "出生日期",
      nation: "民族",
      max: "证件有效日期",
      faceRecognition: "人脸比对",
      signPicture: "授权签名显示",
      authFile: "授权书下载",
      template: "到场记录导入模板.xlsx",
    },
  },
  // 系统更新记录
  update: {
    content: "更新内容",
    time: "更新时间",
    view: "查看具体更新功能",
  },
  period: "。",
  comma: "，",
  space: "至",
  filter: "快速查询",
  action: "操作",
  selectAll: "全选",
  input: "请输入",
  right: "正确的",
  select: "请选择",
  tiao: "条",
  item: "项数据",
  selected: "已选择",
  total: "共",
  noData: "暂无数据",
  tinymcePlaceholder: "在这里输入文字",
  maxInput: "{label}不能超过{length}个字符",
  section: "段",
  leastOne: "至少一个",
  // modal
  homeModal: {
    // 抄送地址modal
    address: {
      title: "自定义邮箱地址",
      member: "全部成员",
      cc: "抄送邮箱",
      ccUniq: "邮箱已存在",
    },
    // 招聘资源modal
    source: {
      remark: "新建到场候选人招聘来源快捷添加",
      dictCode: "来源编码",
      dictCodePlaceholder: "请填写来源编码",
      dictCodeRequired: "来源编码不能为空",
      dictCodeMax: "来源编码不能超过{length}个字符",
      dictName: "来源名称",
      dictNamePlaceholder: "请填写来源名称",
      dictNameRequired: "来源名称不能为空",
      dictNameMax: "来源名称不能超过{length}个字符",
      list: {
        web: "招聘网站",
        meet: "招聘会",
        forum: "论坛",
      },
    },
    // 批量导入modal
    import: {
      step1: {
        title: "上传文件",
        tips: "请上传文件",
        limitSize: "文件不能超过{size}",
        download: {
          title: "填写导入数据信息",
          desc: "导入前请下载最新模板，模板中的表头名称不可更改，表头行不能删除。",
          action: "下载模板",
        },
        upload: {
          title: "上传填好的信息表",
          desc: "文件后缀名必须为xls 或xlsx （即Excel格式），文件大小不得大于10M，最多支持导入3000条数据",
          action: "上传文件",
          message: {
            title: "特别提示",
            content:
              "导入过程中如发现同名客户，且对该客户数据有更新权限，则更新这条客户数据",
          },
        },
        success: "正常数量条数",
        fail: "异常数量条数",
        tip: "异常提示",
      },
      step2: {
        title: "数据预览",
      },
      step3: {
        title: "导入数据",
        success: "批量导入完成",
        successCount: "您已成功导入",
        importing: "正在导入数据",
      },
    },
    // 子账户 modal
    account: {
      info: "账号信息",
      pinfo: "个人信息",
      birthday: "生日",
      email: "邮箱",
      id: "身份证号",
      wechat: "微信",
      phone: "电话",
      amail: "账户邮箱",
      realname: "真实姓名",
      mobile: "手机号码",
      init: "初始密码",
      new: "新密码",
      sex: "性别",
    },
    // 角色 modal
    role: {
      index: "角色",
      title: "编辑角色",
      type: "分类",
      name: "名称",
      desc: "描述",
      create: "创建人",
      createT: "创建时间",
    },
    isList: {
      is: "是",
      not: "否",
    },
    expeditedList: {
      urgent: "加急",
      generally: "一般",
    },
    sexList: {
      female: "女",
      male: "男",
      unknown: "未知",
    },
    progressList: {
      no: "不关注",
      very: "一般关注",
      all: "高度关注",
      general: "全程关注",
    },
    statusList: {
      all: "全部",
      have: "已读",
      unread: "未读",
    },
  },
  // 人事管理
  hrManagement: {
    staffList: {
      tab: "员工管理",
      addBtn: "创建员工",
      editBtn: "详情",
      removeBtn: "离职",
      table: {
        col1: "姓名",
        col2: "手机号码",
        col3: "电子邮箱",
        col4: "部门",
        col5: "状态",
        col6: "剩余{name}",
        col7: "登录次数",
        col8: "最后登录时间",
        col9: "是否启用",
        col10: "操作",
        col11: "用户类型",
      },
      form: {
        name: {
          label: "姓名",
          validation: {
            required: "请输入员工姓名",
            max: "员工姓名不能超过100个字符",
          },
          placeholder: "请输入员工姓名",
        },
        userType: {
          label: "用户类型",
          placeholder: "请选择用户类型",
          validation: {
            required: "用户类型不能为空",
          },
        },
        mobilePhoneCode: {
          label: "区号",
          validation: {
            required: "请选择区号",
          },
          placeholder: "区号",
        },
        mobilePhoneNumber: {
          label: "手机号码",
          validation: {
            required: "请输入员工手机号",
            mobile: "请输入正确的手机号",
          },
          placeholder: "请输入手机号，员工将使用手机号码登录",
        },
        telephoneNumber: {
          label: "办公座机",
          validation: {
            max: "办公座机不能超过{length}个字符",
          },
          placeholder: "请输入员工办公座机",
        },
        emailAddress: {
          label: "电子邮箱",
          validation: {
            required: "请输入员工电子邮箱",
            email: "请输入正确的电子邮箱",
            max: "电子邮箱不能超过100个字符",
          },
          placeholder: "请输入员工电子邮箱",
        },
        gender: {
          label: "性别",
          validation: {
            required: "请选择员工性别",
          },
        },
        birthday: {
          label: "出生日期",
          validation: {
            required: "请选择员工出生日期",
          },
          placeholder: "请选择员工出生日期",
        },
        induction: {
          label: "入职日期",
          validation: {
            required: "请选择员工入职日期",
          },
          placeholder: "请选择员工入职日期",
        },
        master: {
          label: "主账号",
          placeholder: "请选择是否为主账号",
        },
        roleIds: {
          label: "角色",
          placeholder: "请选择员工角色",
        },
        headImg: {
          label: "头像",
          placeholder: "请选择员工头像",
        },
        company: {
          label: "所属公司",
          placeholder: "请选择员工所属公司",
          placeholderFirst: "请先选择员工所属公司",
          required: "所属公司不能为空",
          activeStatus: {
            label: "在职状态",
            placeholder: "请选择员工在职状态",
          },
          isCurrent: {
            label: "当前企业",
            placeholder: "员工是否为当前企业",
          },
          joinGrantPoints: {
            label: "参与发放积分",
            placeholder: "员工是否参与发放积分",
          },
        },
        deptId: {
          label: "所在部门",
          placeholder: "请选择员工所在部门",
        },
        sendMsg: {
          label: "发送激活福利平台的通知",
        },
        balance: {
          label: "剩余{name}",
          returnBtn: "回收{name}",
        },
      },
      addModal: {
        title: "创建员工",
        single: {
          tab: "单个创建",
        },
        multi: {
          tab: "批量创建",
          tips: "请先下载模板，填写后上传即可",
          downloadFile: "批量创建员工",
          required: "请上传批量创建员工文件",
        },
      },
      editModal: {
        title: "编辑员工",
      },
      returnModal: {
        title: "回收{name}",
        name: "员工",
        currency: "币种",
        balance: "剩余{name}",
        points: "回收数量",
        pointsMin: "回收数量不能小于0",
        pointsRequired: "回收数量不能为空",
        remark: "备注",
        remarkMax: "备注不能超过{length}个字符",
      },
      leaveModal: {
        title: "员工离职",
        tip: "请确认是否进行员工离职？",
        returnCoin: "是否回收员工{name}",
      },
    },
    orgList: {
      tab: "组织架构管理",
      addBtn: "导入",
      tips: "* 企业的组织架构已对接钉钉，为保证数据同步，如需修改请前往钉钉修改。",
    },
  },
  // 商品管理
  productManagement: {
    // 商品分类
    category: {
      form: {
        categoryName: {
          label: "商品分类",
          placeholder: "请输入商品分类",
          validation: {
            required: "请输入商品分类",
            max: "商品分类不能超过100个字符",
          },
        },
        categoryParentCode: {
          label: "所属分类",
          placeholder: "请选择所属分类",
          validation: {},
        },
        sort: {
          label: "排序",
          placeholder: "请输入排序",
          validation: {
            required: "请输入排序",
            number: "请输入正确的数字",
          },
        },
        state: {
          label: "是否启用",
          validation: {
            required: "请选择书否启用",
          },
        },
        icon: {
          label: "分类图片",
        },
        description: {
          label: "分类描述",
          placeholder: "请输入分类描述",
          validation: {
            max: "分类描述不能超过500个字符",
          },
        },
        remark: {
          label: "分类概述",
          placeholder: "请输入分类概述",
          validation: {
            max: "分类概述不能超过500个字符",
          },
        },
        back: "返回",
        reset: "重置",
        submit: "提交",
        success: {
          add: "商品分类新增成功",
          edit: "商品分类修改成功",
        },
      },
      list: {
        title: "商品分类",
        search: "请输入商品分类",
        add: "添加商品分类",
        import: "导入",
        export: "导出",
        cols: {
          sort: "序号",
          categoryName: "分类名称",
          icon: "分类图标",
          description: "分类描述",
          categoryParentCode: "所属分类",
          remark: "分类概述",
          createTime: "创建时间",
          state: "启用状态",
        },
      },
    },
    // 商品规格
    spec: {
      list: {
        title: "全部规格",
        add: "添加规格",
        enable: "可用",
        disabled: "已禁用",
        search: "请输入名称等信息",
        cols: {
          sort: "序号",
          attributeName: "规格名称",
          remark: "属性描述",
          attributeTypeName: "属性类型",
          attributeValueTypeName: "属性值类型",
          state: "属性状态",
          createTime: "创建时间",
        },
      },
      form: {
        attributeName: {
          label: "规格名称",
          placeholder: "请填写名称",
          validation: {
            required: "请输入规格名称",
            max: "规格名称不能超过100个字符",
          },
          note: "规格名称作为产生SKU使用",
        },
        state: {
          label: "状态",
          placeholder: "请选择状态",
          validation: {
            required: "请选择状态",
          },
        },
        attributeType: {
          label: "属性类型",
          placeholder: "请选择属性类型",
          validation: {
            required: "请选择属性类型",
          },
        },
        attributeValueType: {
          label: "属性值类型",
          placeholder: "请选择属性值类型",
          validation: {
            required: "请选择属性值类型",
          },
        },
        sort: {
          label: "排序",
          placeholder: "请输入排序",
          validation: {
            required: "请输入排序",
            number: "请输入正确的数字",
          },
        },
        isRequired: {
          label: "是否必填",
          placeholder: "请选择是否必填",
          validation: {
            required: "请选择是否必填",
          },
        },
        isWholeLine: {
          label: "是否整行显示",
          placeholder: "请选择是否整行显示",
          validation: {
            required: "请选择是否整行显示",
          },
        },
        remark: {
          label: "规格描述",
          placeholder: "请填写规格描述",
          validation: {
            max: "规格描述不能超过500个字符",
          },
        },
        reset: "重置",
        submit: "提交",
        success: {
          add: "商品规格新增成功",
          edit: "商品规格修改成功",
        },
      },
    },
    // 促销标签
    promotionalLabel: {
      list: {
        search: "请输入名称等信息",
        title: "促销标签",
        add: "新建促销标签",
        cols: {
          id: "ID",
          img: "图片",
          name: "标签名称",
          type: "标签类型",
          note: "备注",
          state: "状态",
        },
      },
      form: {
        name: {
          label: "标签名称",
          placeholder: "请填写标签名称",
          placeholderSelect: "请选择促销标签",
          required: "标签名称不能为空",
          max: "标签名称不能超过{length}个字符",
        },
        icon: {
          label: "标签图片",
        },
        state: {
          label: "状态",
        },
        promotionType: {
          label: "标签类型",
          placeholder: "请选择标签类型",
          required: "标签类型不能为空",
        },
        remark: {
          label: "标签描述",
          placeholder: "请填写标签描述",
          max: "标签描述不能超过{length}个字符",
        },
      },
    },
    // 商品管理
    product: {
      list: {
        supplier: "供应商",
        supplierPlaceholder: "全部",
        number: "商品编号",
        numberPlaceholder: "商品编号/商品货号",
        brand: "商品品牌",
        brandPlaceholder: "请选择商品品牌",
        name: "商品名称",
        namePlaceholder: "请输入商品名称",
        type: "商品类型",
        typePlaceholder: "请选择商品类型",
        classification: "商品分类",
        classificationPlaceholder: "请选择商品分类",
        createTime: "创建时间",
        createTimePlaceholder: "请选择创建时间",
        AddTime: "上架时间",
        AddTimePlaceholder: "请选择上架时间",
        shelfTime: "下架时间",
        shelfTimePlaceholder: "请选择下架时间",
        title: "全部商品",
        title2: "商品列表",
        cols: {
          sort: "序号",
          img: "商品图片",
          stock: "库存",
          num: "已售数量",
          recommend: "推荐",
          status: "状态",
        },
        add: "新建商品",
        batchRec: "批量推荐",
        tabAll: "全部",
        tabAudit: "待审核",
        tabAdd: "已上架",
        tabShelf: "已下架",
        upShelf: "在售",
        downShelf: "下架",
        selectProduct: "请先选择要操作的商品！",
      },
      detail: {
        invalidProductNo: "商品编码无效！",
        baseInfo: {
          title: "基础信息",
          productName: {
            label: "商品名称",
            placeholder: "请填写商品名称",
            required: "商品名称不能为空",
            max: "商品名称不能超过{length}个字符",
            tips: "商品名称",
          },
          categoryCode: {
            label: "商品类目",
            placeholder: "请选择所属分类",
            required: "商品类目不能为空",
          },
          productType: {
            label: "商品类型",
            placeholder: "请选择商品类型",
            required: "商品类型不能为空",
          },
          advertisement: {
            label: "广告词",
            placeholder: "请填写广告词",
            max: "广告词不能超过{length}个字符",
          },
          keywords: {
            label: "搜索关键词",
            placeholder: "请填写搜索关键词",
            max: "关键词不能超过{length}个字符",
          },
          supplierCode: {
            label: "所属供应商",
            placeholder: "请选择所属供应商",
            required: "所属供应商不能为空",
          },
          freightTemplateId: {
            label: "运费模板",
            placeholder: "请选择运费模板",
            required: "运费模板不能为空",
          },
          isRefund: {
            label: "是否允许退款",
            placeholder: "",
            required: "是否允许退款不能为空",
          },
          pictureUrl: {
            label: "商品主图",
            placeholder: "",
            required: "商品主图不能为空",
          },
          brandCode: {
            label: "品牌",
            placeholder: "请选择品牌",
            required: "品牌不能为空",
            add: "添加品牌",
          },
          taxPoint: {
            label: "税点",
            placeholder: "请填写税点",
            number: "请填写有效数字",
          },
          isRecommend: {
            label: "推荐商品",
          },
          link: {
            label: "供应商商品链接",
            placeholder: "请填写商品链接",
            max: "商品链接不能超过{length}个字符",
          },
          shelfTime: {
            label: "上架时间",
            placeholder: "请选择上架时间",
            required: "上架时间不能为空",
          },
        },
        spec: {
          title: "商品规格",
          add: "新增规格",
          tipsAlways: "最多只能添加2种规格。",
          tips: "如需修改商品规格，请先保存SKU列表！",
          existed: "该商品规格已存在",
          emptySpecsName: "请选择第{sn}组商品规格的【规格名称】",
          emptySpecsValue: "请填写第{sn}组商品规格第{sn2}个【规格值】",
          specsName: {
            label: "规格名称",
            placeholder: "请选择规格名称",
            required: "规格名称不能为空",
          },
          specsValue: {
            label: "规格值",
            placeholder: "请填写规格值",
            required: "规格值不能为空",
            max: "规格值不能超过{length}个字符",
          },
        },
        sku: {
          title: "SKU信息",
          tips: "不存在的SKU信息请编辑为禁用状态。",
          saveTips: "请先保存SKU列表！",
          edit: "编辑SKU",
          save: "保存SKU",
          enable: "启用",
          disable: "禁用",
          enabled: "已启用",
          disabled: "已禁用",
          emptySpecs: "请先添加商品规格",
          emptySku: "请添加商品SKU信息",
          emptySpec: "请选择SKU列表第{sn}行的【{label}】属性值",
          emptySkuName: "请输入SKU列表第{sn}行的【属性名称】",
          emptySalesPrice: "请输入SKU列表第{sn}行的【支付价格】",
          emptyStock: "请输入SKU列表第{sn}行的【库存】",
          invalidBuyCount: "SKU列表第{sn}行的【起售数量】大于【限购数量】！",
          productNo: {
            label: "货号",
            placeholder: "自动生成",
          },
          attr: {
            label: "属性",
            placeholder: "请选择属性值",
          },
          skuName: {
            label: "属性名称",
            placeholder: "请填写属性名称",
            required: "属性名称不能为空",
            max: "属性名称不能超过{length}个字符",
          },
          salesPrice: {
            label: "支付价格",
            placeholder: "请填写支付价格",
            number: "请填写有效数字",
          },
          pictureUrl: {
            label: "主图",
          },
          stock: {
            label: "库存",
            placeholder: "请填写库存",
            number: "请填写有效数字",
          },
          minBuyCount: {
            label: "起售数量",
            placeholder: "请填写起售数量",
            number: "请填写有效数字",
          },
          maxBuyCount: {
            label: "限购数量",
            placeholder: "请填写限购数量",
            number: "请填写有效数字",
          },
        },
        others: {
          title: "其他",
          detail: "商品详情",
          spec: "规格参数",
          exchange: "兑换流程",
          afterSales: "售后保障",
        },
        success: {
          add: "保存成功",
          edit: "修改成功",
        },
      },
    },
  },
  // 供应商管理
  supplierManagement: {
    // 供应商管理
    supplier: {
      title: "全部供应商",
      list: {
        title: "已添加供应商",
        allTitle: "全部供应商",
        location: "供应商所属地",
        province: "省份",
        city: "城市",
        area: "地区",
        add: "添加供应商",
        import: "导入",
        export: "导出",
        batchDelConfirm: "确认要删除这些数码吗？",
        cols: {
          sort: "序号",
          number: "供应商编码",
          name: "供应商名称",
          acct: "公司联系电话",
          email: "公司邮箱",
          person: "负责人",
          createTime: "创建时间",
          method: "结算方式",
        },
      },
      form: {
        info: "基础信息",
        supplierName: "供应商名称",
        supplierCode: "供应商编码",
        supplierNamePlaceholder: "供应商名称",
        supplierCodePlaceholder: "供应商编码",
        supplierNameRequired: "供应商名称不能为空",
        supplierCodeRequired: "供应商编码不能为空",
        supplierNameMax: "供应商名称不能超过{length}个字符",
        supplierCodeMax: "供应商编码不能超过{length}个字符",
        supplierNameSimple: "供应商简称",
        supplierNameSimplePlaceholder: "供应商简称",
        supplierNameSimpleRequired: "供应商简称不能为空",
        supplierNameSimpleMax: "供应商简称不能超过{length}个字符",
        province: "所在区域",
        provincePlaceholder: "请选择",
        provinceRequired: "请选择所在区域省份",
        city: "市/区",
        cityPlaceholder: "请选择",
        cityRequired: "请选择所在区域市区",
        paymentMethod: "结算方式",
        paymentMethodPlaceholder: "请填写结算方式(天)",
        paymentMethodRequired: "请填写结算方式",
        rAddress: "常用退货地址",
        returnPhone: "手机号码",
        returnPhonePlaceholder: "请填写手机号码",
        returnPhoneRequired: "手机号码不能为空",
        returnPhoneReg: "请输入正确的手机号码",
        returnReceiver: "收件人",
        returnReceiverPlaceholder: "请填写收件人",
        returnReceiverRequired: "收件人不能为空",
        returnReceiverMax: "收件人不能超过{length}个字符",
        returnAddress: "收货地址",
        returnAddressPlaceholder: "请填写收货地址",
        returnAddressRequired: "收货地址不能为空",
        returnAddressMax: "收货地址不能超过{length}个字符",
        company: "公司详情",
        icon: "供应商LOGO图片",
        iconPlaceholder: "",
        iconRequired: "供应商LOGO图片不能为空",
        detailAddress: "详细地址",
        detailAddressPlaceholder: "请填写详细地址",
        detailAddressRequired: "详细地址不能为空",
        detailAddressMax: "详细地址不能超过{length}个字符",
        zipCode: "邮编",
        zipCodePlaceholder: "请填写邮编",
        zipCodeMax: "邮编不能超过{length}个字符",
        contactPerson: "联系人姓名",
        contactPersonPlaceholder: "请填写联系人姓名",
        contactPersonRequired: "联系人姓名不能为空",
        contactPersonMax: "联系人姓名不能超过{length}个字符",
        contactPhone: "电话",
        contactPhonePlaceholder: "请填写电话",
        contactPhoneRequired: "电话不能为空",
        contactPhoneMax: "电话不能超过{length}个字符",
        contactEmail: "邮箱",
        contactEmailPlaceholder: "请填写邮箱",
        contactEmailRequired: "邮箱不能为空",
        contactEmailMax: "邮箱不能超过{length}个字符",
        contactEmailType: "请填写正确的邮箱地址",
        businessScope: "经营范围",
        businessScopePlaceholder: "请输入介绍信息",
        businessScopeRequired: "经营范围不能为空",
        businessScopeMax: "经营范围不能超过{length}个字符",
        financial: "财务信息",
        taxNumber: "税号",
        taxNumberPlaceholder: "请填写税号",
        taxNumberRequired: "税号不能为空",
        taxNumberMax: "税号不能超过{length}个字符",
        invoiceHead: "发票抬头",
        invoiceHeadPlaceholder: "请填写发票抬头",
        invoiceHeadRequired: "发票抬头不能为空",
        invoiceHeadMax: "发票抬头不能超过{length}个字符",
        bank: "开户银行",
        bankPlaceholder: "请填写开户银行",
        bankRequired: "开户银行不能为空",
        bankMax: "开户银行不能超过{length}个字符",
        bankNumber: "银行账号",
        bankNumberPlaceholder: "请填写银行账号",
        bankNumberRequired: "银行账号不能为空",
        bankNumberMax: "银行账号不能超过{length}个字符",
        financePhoneNumber: "电话号码",
        financePhoneNumberPlaceholder: "请填写电话号码",
        financePhoneNumberRequired: "电话号码不能为空",
        financePhoneNumberMax: "电话号码不能超过{length}个字符",
        companyAddress: "单位地址",
        companyAddressPlaceholder: "请填写单位地址",
        companyAddressRequired: "单位地址不能为空",
        companyAddressMax: "单位地址不能超过{length}个字符",
        customInfo: "客户信息",
        adminAccount: "管理员账号",
        adminAccountPlaceholder: "请填写管理员账号",
        adminAccountRequired: "管理员账号不能为空",
        adminAccountMax: "管理员账号不能超过{length}个字符",
        principal: "负责人",
        principalPlaceholder: "请选择业务员",
        principalRequired: "负责人不能为空",
        supplierLevel: "供应商级别",
        supplierLevelPlaceholder: "请选择客户级别",
        supplierLevelRequired: "供应商级别不能为空",
        signingTime: "签约时间",
        signingTimePlaceholder: "请选择日期",
        signingTimeRequired: "签约时间不能为空",
        remark: "备注信息",
        remarkPlaceholder: "请填写备注信息",
        remarkMax: "备注信息不能超过{length}个字符",
        success: {
          add: "保存成功",
          edit: "修改成功",
        },
      },
    },
    // 品牌管理
    brand: {
      list: {
        title: "已添加品牌",
        add: "添加品牌",
        import: "导入",
        export: "导出",
        search: "品牌名称",
        enable: "可用",
        disabled: "已禁用",
        cols: {
          sort: "序号",
          logo: "LOGO",
          brandName: "品牌名称",
          account: "供应商管理员账号",
          createBy: "创建人",
          remark: "品牌概述",
          state: "状态",
          createTime: "创建时间",
        },
      },
      form: {
        brandCode: {
          label: "品牌编码",
          placeholder: "请填写品牌编码",
          validation: {
            required: "请输入品牌编码",
            max: "品牌编码不能超过100个字符",
          },
        },
        brandName: {
          label: "品牌名称",
          placeholder: "请填写品牌名称",
          validation: {
            required: "请输入品牌名称",
            max: "品牌名称不能超过100个字符",
          },
        },
        website: {
          label: "品牌网址",
          placeholder: "请填写品牌网址",
          validation: {
            required: "请输入品牌网址",
            max: "品牌网址不能超过500个字符",
          },
        },
        icon: {
          label: "品牌logo",
        },
        sort: {
          label: "排序",
          placeholder: "请输入排序",
          validation: {
            required: "请输入排序",
            number: "请输入正确的数字",
          },
        },
        state: {
          label: "状态",
          placeholder: "请选择状态",
          validation: {
            required: "请选择状态",
          },
        },
        isRecommend: {
          label: "是否推荐",
          placeholder: "请选择是否推荐",
          validation: {
            required: "请选择是否推荐",
          },
        },
        remark: {
          label: "备注",
          placeholder: "请填写备注",
          validation: {
            max: "备注不能超过500个字符",
          },
        },
        reset: "重置",
        submit: "提交",
        success: {
          add: "品牌新增成功",
          edit: "品牌修改成功",
        },
      },
    },
    // 优惠券管理
    coupon: {
      list: {
        title: "优惠券管理",
        add: "添加优惠券",
        search: "优惠券名称",
        cols: {
          sort: "序号",
          name: "名称",
          couponId: "编码",
          type: "类型",
          total: "数量",
          status: "状态",
          discount: "优惠金额或折数",
          min: "最少消费金额",
          days: "有效天数",
          startTime: "使用开始时间",
          endTime: "使用截至时间",
          content: "介绍",
        },
      },
      form: {
        name: {
          label: "优惠券名称",
          placeholder: "请填写优惠券名称",
          required: "请输入优惠券名称",
          max: "优惠券名称不能超过{length}个字符",
        },
        couponId: {
          label: "优惠券编码",
        },
        type: {
          label: "优惠券类型",
          placeholder: "请选择优惠券类型",
          required: "请选择优惠券类型",
        },
        total: {
          label: "优惠券数量",
          placeholder: "请填写优惠券数量",
          required: "请输入优惠券数量",
        },
        status: {
          label: "优惠券状态",
          placeholder: "请选择优惠券状态",
          required: "请选择优惠券状态",
        },
        discount: {
          label1: "优惠券折数",
          label2: "优惠金额",
          placeholder1: "请填写折数，比如九折是90",
          placeholder2: "请填写优惠金额，单位元",
          required: "请填写优惠金额或折数",
        },
        min: {
          label: "最少消费金额",
          placeholder: "请填写最少消费金额，单位元",
        },
        days: {
          label: "有效天数",
          required: "请填写有效天数",
          placeholder: "请填写有效天数",
        },
        startTime: {
          label: "使用券开始时间",
          placeholder: "请选择使用券开始时间",
        },
        endTime: {
          label: "使用券截至时间",
          placeholder: "请选择使用券截至时间",
        },
        content: {
          label: "优惠券介绍",
          placeholder: "请填写优惠券介绍",
          required: "请填写优惠券介绍",
          max: "优惠券介绍不能超过{length}个字符",
        },
      },
    },
  },
  // 订单管理
  orderManagement: {
    // 订单管理
    order: {
      list: {
        title: "订单列表",
        filters: {
          orderId: {
            label: "订单编号",
            placeholder: "请填写订单编号",
          },
          supplier: {
            label: "供应商",
            placeholder: "请选择供应商",
          },
          orderAmount: {
            label: "订单金额",
            placeholderMin: "最小金额",
            placeholderMax: "最大金额",
          },
          createTime: {
            label: "下单时间",
            placeholder: "请选择下单时间",
          },
          state: {
            label: "订单状态",
            placeholder: "请选择订单状态",
          },
          productName: {
            label: "商品名称",
            placeholder: "请填写商品名称",
          },
          name: {
            label: "收货人姓名",
            placeholder: "请填写收货人姓名",
          },
          phone: {
            label: "收货人电话",
            placeholder: "请填写收货人电话",
          },
          productType: {
            label: "商品类型",
            placeholder: "请选择所属分类",
          },
        },
        cols: {
          orderId: "订单编号",
          createTime: "提交时间",
          payPrice: "实付金额",
          productName: "商品名称",
          remark: "订单备注",
          stateName: "订单状态",
          discountTypeName: "促销方式",
        },
      },
      detail: {
        stateTips: "当前订单状态",
        invalidOrderId: "订单编码无效！",
        confirm: "退款",
        confirmRefund: "确认进行退款吗？",
        refundSuccess: "退款操作成功！",
        send: "发货",
        confirmSend: "确认已经发货了吗？",
        sendSuccess: "发货成功！",
        progress: {
          title: "订单进度",
          createOrder: "提交订单",
          payOrder: "支付订单",
          payOrder1: "已支付",
          payOrder2: "未支付",
          sendOrder: "平台发货",
          sendOrder1: "已发货",
          sendOrder2: "未发货",
          receivedOrder: "确认收货",
          receivedOrder1: "已收货",
          receivedOrder2: " 待收货",
          doneOrder: "完成评价",
        },
        deliveryInfo: {
          title: "发货信息",
          expName: {
            label: "物流公司",
            placeholder: "请选择物流公司",
            validation: {
              required: "请选择物流公司",
            },
          },
          number: {
            label: "运单号码",
            placeholder: "请填写运单号码",
            validation: {
              required: "请填写运单号码",
              max: "运单号码不能超过{length}个字符",
            },
          },
          ship: "发货",
          refund: "万不得已的退款",
        },
        receiverInfo: {
          title: "收货人信息",
          cols: {
            consignee: "收货人",
            phone: "手机号码",
            zipCode: "邮政编码",
            address: "收货地址",
          },
        },
        baseInfo: {
          title: "基本信息",
          cols: {
            orderId: "订单编号",
            orderNumber: "发货单流水号",
            account: "用户账号",
            payWay: "支付方式",
            orderSource: "订单来源",
            orderTypeName: "订单类型",
            deliveryMethod: "配送方式",
            expNumber: "物流单号",
            autoReceived: "自动确认收货时间",
            orderAward: "订单可得优币",
            orderPoints: "订单可得成长值",
            activityInfo: "活动信息",
          },
        },
        invoiceInfo: {
          title: "发票信息",
          cols: {
            invoiceType: "发票类型",
            invoiceHead: "发票抬头",
            invoiceContent: "发票内容",
            invoiceInfo: "收票人信息",
          },
        },
        productInfo: {
          title: "商品信息",
          cols: {
            productUrl: "商品图片",
            productName: "商品名称",
            productPrice: "价格/货号",
            productPrice1: "价格",
            productPrice2: "货号",
            skuName: "属性",
            number: "数量",
            productStock: "库存",
            subtotal: "小计",
          },
          total: "合计：",
        },
        feeInfo: {
          title: "费用信息",
          cols: {
            aaa1: "商品合计",
            freightPoints: "运费",
            aaa2: "优惠券",
            aaa3: "成长积分抵扣",
            aaa4: "活动优惠",
            aaa5: "折扣金额",
            aaa6: "订单总金额",
            aaa7: "应付款金额",
          },
        },
        operatingInfo: {
          title: "操作信息",
          cols: {
            aaa1: "操作者",
            aaa2: "操作时间",
            aaa3: "订单状态",
            aaa4: "付款状态",
            aaa5: "发货状态",
            aaa6: "备注",
          },
        },
      },
    },
    // 售后管理
    aftersales: {
      list: {
        title: "售后订单列表",
        cols: {
          id: "售后编号",
          orderId: "订单编号",
          applyTime: "申请时间",
          name: "商品名称",
          money: "退款金额",
          reason: "退款原因",
          isReject: "是否退货",
          status: "审核状态",
          tips: "确定同意退货吗？",
        },
      },
    },
  },
  // 资源中心
  resource: {
    list: {
      button: "资源兑换",
      balance: "账户余额",
      back: "背调币",
      welfare: "福利币",
      survey: "调研币",
      record: "动账记录",
      time: "动账时间",
      cols: {
        time: "时间",
        purpose: "用途",
        currency: "币种",
        exchangePoints: "金额",
        exchangeAfterPoints: "余额",
      },
      tips: {
        cash: "仅可通过线下充值获得<br>可用于结算易查的背调服务，也可兑换成其他服务货币",
        back: "{currency}用于背调服务的结算<br>可在资源中心兑换获得",
        wel: "{currency}可发放给员工兑换福利商品<br>可在资源中心兑换获得",
        survey: "{currency}可购买问卷题目和调研员工数<br>可在资源中心兑换获得",
      },
    },
    modal: {
      currency: "目标币种",
      amount: "兑换金额",
      balance: "兑换所需余额",
      surplus: "兑换后剩余余额",
      warnTip: "当前币种可用余额不足",
      amountMin: "兑换金额必须大于0",
    },
  },
  // 会员中心
  member: {
    base: {
      tab: "基本信息",
      form: {
        enterName: "企业名称",
        name: "姓名",
        shortName: "企业简称",
        mobile: "手机号码",
        trade: "所属行业",
        email: "电子邮箱",
        reportReceiveEmail: "接收报告邮箱",
        city: "所在城市",
        mainAcct: "是否主账号",
        avatar: "头像",
      },
      uploadBtn: "选择需要上传的头像",
      submitBtn: "提交头像",
      preview: "头像预览",
      editPsd: "修改密码",
      editAvatar: "修改头像",
      psd: {
        loginPsd: "登录密码",
        loginPsdPh: "请输入当前使用的密码",
        currentLogin: "当前密码",
        newLogin: "新密码",
        confirmLogin: "确认密码",
        payPsd: "支付密码",
        currentPay: "当前支付密码",
        newPay: "新支付密码",
        confirmPay: "确认新支付密码",
        tips: "新密码和确认密码输入不一致",
      },
    },
    acct: {
      staff: "员工",
      tab: "子账号",
      auth: "权限",
      time: "最后登录时间",
      start: "是否启用",
      squareAudit: "广场审核权限",
      squareAuditTitle: "福利平台广场审核",
      squareAuditConfirmOpen: "确认为{name}开通福利平台广场审核权限？",
      squareAuditConfirmClose: "确认关闭{name}的福利平台广场审核权限？",
      create: "创建子账号",
      initPsd: "初始密码",
      initPsdPlaceholder: "请输入子账号登录密码，最少6位",
    },
    score: {
      tab: "HR成长积分",
      tip: "HR成长积分是系统对您在日常工作中的感谢和奖励，可在EC商城中使用。",
      useRecord: "使用记录",
      noneUseRecord: "暂无使用记录",
      table: {
        time: "积分获取时间",
        source: "来源",
        number: "数量",
        rest: "剩余",
      },
    },
    bind: {
      tab: "生态绑定",
      beisen: {
        account: "服务商账号",
        secret: "密钥",
        createConfirm: "创建密钥后，您需要使用北森平台进行绑定操作。",
        removeConfirm: "是否移除绑定？",
        create: "创建密钥",
        view: "查看密钥",
        remove: "移除绑定",
        copy: "复制密钥",
        tips: "北森绑定操作提示",
        help: "使用帮助",
      },
      moka: {
        noUrl: "如需绑定请联系与您对接的商务",
        unbind: "解绑",
        binded: "已绑定",
      },
      feishu: {
        account: "服务商账号",
        secret: "密钥",
        logoFileName: "logo-feishu_hire-cn.svg",
        create: "创建密钥",
        view: "查看密钥",
        copy: "复制密钥",
        reset: "重置密钥",
        help: "使用帮助",
        tips: "飞书招聘系统绑定操作指引",
        createConfirm: "您需要前往飞书招聘系统绑定。",
        resetConfirm:
          "密钥重置后，您需要到飞书招聘系统后台设置，删除并重新绑定账号，是否继续？",
        cancelOperateBlockedMessage:
          "为避免与飞书招聘系统产生数据差异，请通过飞书招聘系统进行此操作",
        updatePackage: "更新套餐",
      },
    },
  },
  // 配送管理
  express: {
    company: {
      list: {
        cols: {
          start: "是否启用",
          name: "名称",
        },
      },
    },
    cost: {
      filter: {
        area: "地区",
        supplier: "供应商名称",
        amount: "金额(分)",
        template: "模板名称",
      },
      cols: {
        index: "序号",
        goodsAmount: "使用商品数量",
        area: "可配送区域",
      },
      edit: {
        area: "配送区域",
        chargeArea: "收费区域(未配置的地区默认包邮)",
        freight: "运费(元)",
        addArea: "添加收费区域",
      },
      modal: {
        title: "选择收费区域",
        supplier: "供应商",
      },
    },
  },
  // 系统管理
  system: {
    dic: {
      title: "数据字典",
      search: "字典名称、编码",
      add: "添加字典",
      modal: "编辑字典",
      cols: {
        index: "序号",
        sort: "排序",
        dictType: "字典类型",
        dictName: "字典名称",
        dictCode: "字典编码",
        dictIcon: "字典图标",
        state: "状态",
        remark: "备注",
      },
      form: {
        dictType: {
          label: "字典类型",
          placeholder: "请选择字典类型",
          required: "字典类型不能为空",
        },
        dictCode: {
          label: "字典编码",
          placeholder: "请填写字典编码",
          required: "字典编码不能为空",
          max: "字典编码不能超过{length}个字符",
        },
        dictName: {
          label: "字典名称",
          placeholder: "请填写字典名称",
          required: "字典名称不能为空",
          max: "字典名称不能超过{length}个字符",
        },
        sort: {
          label: "排序",
          placeholder: "请填写排序",
          required: "排序不能为空",
          number: "排序必须为有效数字",
        },
        state: {
          label: "状态",
          placeholder: "请选择状态",
          required: "状态不能为空",
        },
        dictIcon: {
          label: "字典图标",
        },
        remark: {
          label: "备注",
          placeholder: "请填写备注",
          max: "备注不能超过{length}个字符",
        },
      },
    },
    menu: {
      title: "菜单管理",
      search: "菜单名称",
      add: "添加菜单",
      modal: "编辑菜单",
      cols: {
        sort: "排序",
        name: "菜单名称",
        href: "链接",
        sysType: "平台类型",
      },
      form: {
        name: {
          label: "菜单名称",
          placeholder: "请填写菜单名称",
          required: "菜单名称不能为空",
          max: "菜单名称不能超过{length}个字符",
        },
        parentId: {
          label: "父级菜单",
          placeholder: "请选择父级菜单",
        },
        href: {
          label: "链接",
          placeholder: "请填写链接",
          max: "链接不能超过{length}个字符",
        },
        sysType: {
          label: "平台类型",
          placeholder: "请选择平台类型",
          required: "平台类型不能为空",
        },
        type: {
          label: "菜单类型",
          placeholder: "请选择菜单类型",
          required: "菜单类型不能为空",
        },
        sort: {
          label: "排序",
          placeholder: "请填写排序",
          required: "排序不能为空",
          number: "排序必须为有效数字",
        },
      },
    },
    role: {
      title: "角色管理",
      search: "角色名称",
      add: "添加角色",
      modal: "编辑角色",
      permission: "绑定权限",
      cols: {
        sort: "排序",
        name: "角色名称",
        roleId: "角色编码",
        sysType: "平台类型",
        remark: "备注",
      },
      form: {
        name: {
          label: "角色名称",
          placeholder: "请填写角色名称",
          required: "角色名称不能为空",
          max: "角色名称不能超过{length}个字符",
        },
        roleId: {
          label: "角色编码",
          placeholder: "请填写角色编码",
          required: "角色编码不能为空",
          max: "角色编码不能超过{length}个字符",
        },
        sysType: {
          label: "平台类型",
          placeholder: "请选择平台类型",
          required: "平台类型不能为空",
        },
        remark: {
          label: "备注",
          placeholder: "请填写备注",
          max: "备注不能超过{length}个字符",
        },
      },
    },
    user: {
      title: "用户管理",
      search: "姓名",
      add: "添加用户",
      modal: "编辑用户",
      cols: {
        sort: "排序",
        name: "姓名",
        deptId: "部门",
        mobilePhoneNumber: "手机号",
        gender: "性别",
        emailAddress: "邮件地址",
        points: "积分",
      },
      form: {
        name: {
          label: "姓名",
          placeholder: "请填写姓名",
          required: "姓名不能为空",
          max: "姓名不能超过{length}个字符",
        },
        companyId: {
          label: "所属公司",
          placeholder: "请选择所属公司",
          required: "所属公司不能为空",
        },
        deptId: {
          label: "部门",
          placeholder: "请选择部门",
          required: "部门不能为空",
        },
        userType: {
          label: "用户类型",
          placeholder: "请选择用户类型",
          required: "用户类型不能为空",
        },
        roleIds: {
          label: "用户角色",
          placeholder: "请选择用户角色",
          required: "用户角色不能为空",
        },
        master: {
          label: "主账号",
        },
        gender: {
          label: "性别",
          placeholder: "请选择性别",
        },
        birthday: {
          label: "出生日期",
          placeholder: "请选择出生日期",
        },
        mobilePhoneNumber: {
          label: "手机号",
          placeholder: "请填写手机号",
          required: "手机号不能为空",
          mobile: "手机号格式错误",
        },
        telephoneNumber: {
          label: "办公座机",
          placeholder: "请填写办公座机",
        },
        emailAddress: {
          label: "邮件地址",
          placeholder: "请填写邮件地址",
          required: "邮件地址不能为空",
          email: "请输入正确的邮件地址",
        },
        induction: {
          label: "入职日期",
          placeholder: "请选择入职日期",
        },
        sendMsg: {
          label: "激活通知",
        },
        userCompany: {
          label: "公司信息",
        },
      },
    },
    org: {
      title: "组织架构",
      search: "部门名称",
      companyId: "公司",
      add: "添加组织架构",
      modal: "编辑组织架构",
      cols: {
        sort: "排序",
        deptName: "部门名称",
        deptId: "部门编号",
        companyId: "企业ID",
        delFlag: "状态",
        remark: "备注",
      },
      form: {
        companyId: {
          label: "所属企业",
          placeholder: "请选择所属企业",
          required: "所属企业不能为空",
        },
        deptName: {
          label: "部门名称",
          placeholder: "请填写部门名称",
          required: "部门名称不能为空",
          max: "部门名称不能超过{length}个字符",
        },
        deptId: {
          label: "部门编号",
          placeholder: "请选择部门编号",
        },
        parentId: {
          label: "上级部门",
          placeholder: "请选择上级部门",
        },
        remark: {
          label: "备注",
          placeholder: "请填写备注",
          max: "备注不能超过{length}个字符",
        },
      },
    },
    conf: {
      title: "系统配置",
      search: "系统配置名称、编码",
      add: "添加系统配置",
      modal: "编辑系统配置",
      cols: {
        sort: "排序",
        name: "名称",
        code: "编码",
        value: "配置值",
        delFlag: "状态",
        isEncryption: "加密",
        remark: "备注",
      },
      form: {
        code: {
          label: "编码",
          placeholder: "请填写编码",
          required: "编码不能为空",
          max: "编码不能超过{length}个字符",
        },
        name: {
          label: "名称",
          placeholder: "请填写名称",
          required: "名称不能为空",
          max: "名称不能超过{length}个字符",
        },
        value: {
          label: "配置值",
          placeholder: "请填写配置值",
          required: "配置值不能为空",
          max: "配置值不能超过{length}个字符",
        },
        sort: {
          label: "排序",
          placeholder: "请填写排序",
          required: "排序不能为空",
          number: "排序必须为有效数字",
        },
        state: {
          label: "状态",
          required: "状态不能为空",
        },
        isEncryption: {
          label: "是否进行加密",
          required: "是否进行加密不能为空",
        },
        remark: {
          label: "备注",
          placeholder: "请填写备注",
          max: "备注不能超过{length}个字符",
        },
      },
    },
    company: {
      title: "公司管理",
      search: "公司名称",
      add: "添加公司",
      modal: "编辑公司",
      cols: {
        sort: "排序",
        name: "公司名称",
        nameShort: "公司简称",
        companyId: "企业id",
        signType: "签约状态",
        points: "余额",
        contactName: "联系人",
        contactPhone: "手机号",
        contactEmail: "邮箱",
      },
      form: {
        base: {
          title: "基础信息",
          name: {
            label: "中文名称",
            placeholder: "请填写企业中文名称",
            required: "中文名称不能为空",
            max: "中文名称不能超过{length}个字符",
          },
          companyId: {
            label: "企业编码",
          },
          nameShort: {
            label: "中文简称",
            placeholder: "请填写企业中文简称",
            max: "中文简称不能超过{length}个字符",
          },
          nameOther: {
            label: "第三语言名称",
            placeholder: "请填写企业第三语言名称",
            max: "第三语言名称不能超过{length}个字符",
          },
          nameOtherShort: {
            label: "第三语言简称",
            placeholder: "请填写企业第三语言简称",
            max: "第三语言简称不能超过{length}个字符",
          },
          parentCompanyId: {
            label: "上级企业",
            placeholder: "请选择上级企业",
          },
          categoryId: {
            label: "客户类别",
            placeholder: "请选择客户类别",
          },
          industry: {
            label: "所属行业",
            placeholder: "请选择所属行业",
            required: "所属行业不能为空",
          },
          businessUserId: {
            label: "所属商务",
            placeholder: "请选择所属商务",
          },
          scaleId: {
            label: "企业规模",
            placeholder: "请选择企业规模",
          },
          sourceId: {
            label: "企业来源",
            placeholder: "请选择企业来源",
          },
          typeId: {
            label: "企业类型",
            placeholder: "请选择企业类型",
          },
          taxCode: {
            label: "社会信用代码",
            placeholder: "请输入统一社会信用代码",
            required: "统一社会信用代码不能为空",
            max: "统一社会信用代码不能超过{length}个字符",
          },
          serviceUserId: {
            label: "所属客服",
            placeholder: "请选择所属客服",
          },
          logo: {
            label: "LOGO",
          },
          country: {
            label: "国家",
            placeholder: "请输入企业所在国家",
            max: "国家不能超过{length}个字符",
          },
          province: {
            label: "地址",
            placeholder: "省",
            required: "地址信息必填",
          },
          city: {
            label: "",
            placeholder: "市",
            required: "地址信息必填",
          },
          district: {
            label: "",
            placeholder: "区",
            required: "地址信息必填",
          },
          address: {
            label: "详细地址",
            placeholder: "请填写企业详细地址",
            max: "详细地址不能超过{length}个字符",
          },
          intro: {
            label: "简介",
            placeholder: "请填写企业简介",
            max: "简介不能超过{length}个字符",
          },
        },
        hrList: {
          title: "联系人信息",
          add: "添加联系人",
          cols: {
            name: "姓名",
            namePlaceholder: "请填写姓名",
            nameRequired: "姓名不能为空",
            nameMax: "姓名不能超过{length}个字符",
            phone: "手机号",
            phonePlaceholder: "请填写手机号",
            phoneRequired: "手机号不能为空",
            phoneMax: "手机号不能超过{length}个字符",
            master: "主账号",
            masterOne: "联系人只能有一个主账号",
            email: "电子邮箱",
            emailPlaceholder: "请填写电子邮箱",
            emailRequired: "电子邮箱不能为空",
            emailMax: "电子邮箱不能超过{length}个字符",
            emailType: "请输入正确的电子邮箱",
          },
        },
        signList: {
          title: "签约信息",
          add: "添加签约信息",
          cols: {
            companyId: "企业编码",
            companyIdPlaceholder: "请输入企业编码",
            companyIdRequired: "企业编码不能为空",
            companyIdMax: "企业编码不能超过{length}个字符",
            type: "签约类型",
            typePlaceholder: "选择类型",
            typeRequired: "签约类型不能为空",
            points: "金额",
            pointsPlaceholder: "请输入金额",
            pointsRequired: "金额不能为空",
            status: "状态",
            statusPlaceholder: "选择状态",
            statusRequired: "状态不能为空",
            startTime: "开始时间",
            startTimePlaceholder: "请选择签约开始时间",
            endTime: "结束时间",
            endTimePlaceholder: "请选择签约结束时间",
            endTimeValid: "结束时间必须晚于开始时间",
          },
        },
        resource: {
          title: "资源信息",
          backToneCoin: {
            label: "背调币",
            placeholder: "请填写背调币",
          },
          benefitsCoin: {
            placeholder: "请填写{name}",
          },
          points: {
            label: "积分",
            placeholder: "请填写积分",
          },
          surveyCoin: {
            label: "调研币",
            placeholder: "请填写调研币",
          },
        },
        dictCode: {
          label: "字典编码",
          placeholder: "请填写字典编码",
          required: "字典编码不能为空",
          max: "字典编码不能超过{length}个字符",
        },
        dictName: {
          label: "字典名称",
          placeholder: "请填写字典名称",
          required: "字典名称不能为空",
          max: "字典名称不能超过{length}个字符",
        },
        sort: {
          label: "排序",
          placeholder: "请填写排序",
          required: "排序不能为空",
          number: "排序必须为有效数字",
        },
        state: {
          label: "状态",
          placeholder: "请选择状态",
          required: "状态不能为空",
        },
        dictIcon: {
          label: "字典图标",
        },
        remark: {
          label: "备注",
          placeholder: "请填写备注",
          max: "备注不能超过{length}个字符",
        },
      },
    },
    // 推荐banner管理
    banner: {
      title: "Banner图管理",
      bannerType: "Banner图类型",
      add: "添加Banner",
      modal: "编辑Banner",
      cols: {
        sort: "排序",
        bannerPictureUrl: "图片",
        bannerLink: "链接地址",
        bannerType: "类型",
        state: "状态",
        remark: "备注",
      },
      form: {
        bannerType: {
          label: "类型",
          placeholder: "请选择类型",
          required: "类型不能为空",
        },
        bannerPictureUrl: {
          label: "图片",
          placeholder: "请选择Banner图片",
          required: "图片不能为空",
        },
        bannerLink: {
          label: "链接地址",
          placeholder: "请填写链接地址",
          max: "链接地址不能超过{length}个字符",
        },
        sort: {
          label: "排序",
          placeholder: "请填写排序",
          required: "排序不能为空",
          number: "排序必须为有效数字",
        },
        state: {
          label: "状态",
          placeholder: "请选择状态",
          required: "状态不能为空",
        },
        remark: {
          label: "备注",
          placeholder: "请填写备注",
          max: "备注不能超过{length}个字符",
        },
      },
    },
    exchange: {
      list: {
        title: "企业兑换比例",
        company: "企业名称",
        from: "来源币种",
        to: "转换币种",
        ratio: "兑换比例",
        remark: "备注",
      },
    },
    benefits: {
      add: "添加福利",
      search: "福利名称",
      title: "系统福利",
      distribution: {
        title: "分配系统福利",
        btn: "批量分配",
        companyIds: "分配企业",
        companyIdsRequired: "分配企业不能为空",
        companyIdsPlaceholder: "请选择分配企业",
        emptySelected: "请选择要分配的福利",
      },
    },
  },
  // 审核
  audit: {
    pass: "已通过",
    rejected: "已拒绝",
    pending: "待审核",
    cols: {
      index: "序号",
      publisher: "发布人",
      content: "发布内容",
      time: "发布时间",
      status: "审核状态",
      reason: "拒绝原因",
    },
    modal: {
      awardNum: "奖励{unit}",
      tip: "可用 {value} {unit}",
      payPwd: "支付密码",
    },
  },
  // 调查问卷
  survey: {
    order: {
      label: "发送性格测试问卷",
      link: "查看样本报告",
    },
    description: {
      title: "性格测试问卷",
      p1: "您选择发布问卷后，将问卷将在候选人上传完成背调资料后，通过短信或邮件的方式发送。若您取消背调订单，则同时下单的性格测试问卷也将随之取消。",
      p2: "性格测试问卷限时免费，欢迎使用体验。",
    },
    index: {
      add: "发布问卷",
      title: "问卷明细",
      table: {
        name: "姓名",
        title: "问卷名称",
        amount: "调研点",
        notify: "通知方式",
        phone: "电话号码",
        email: "邮箱地址",
        create: "创建时间",
        send: "发送时间",
        submit: "填写提交时间",
        appointment: "是否预约发送",
        status: "问卷状态",
      },
      confirm: "操作确认",
      confirmText1: "确定要取消对",
      confirmText2: "的问卷调查吗？",
      form: {
        cost: "使用{val}{name}",
        nameRequired: "姓名不能为空",
        nameMax: "姓名不能超过{len}个字符",
        notifyRequired: "通知方式不能为空",
        emailRequired: "邮箱地址不能为空",
        emailMax: "邮箱地址不能超过{len}个字符",
        emailReg: "请输入正确的邮箱地址",
        phoneRequired: "电话号码不能为空",
        phoneMax: "邮箱地址不能超过{len}个字符",
        employee: {
          name: "姓名",
          dept: "部门",
          email: "邮箱地址",
          phone: "电话号码",
        },
        employeeRequired: "请选择员工",
        candidate: {
          name: "姓名",
          idcard: "身份证号",
          email: "邮箱地址",
          phone: "电话号码",
        },
        candidateRequired: "请选择候选人",
        import: {
          fileName: "发布问卷模板",
          upload: "请选择文件上传",
        },
        confirm: "操作确认",
      },
    },
  },
};
